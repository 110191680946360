.yp-case-study {
  display: grid;
  margin-top: 48px;
  grid-template-columns: 1087px;
  justify-content: center;
}

@media only screen and (max-width: 1140px) {
  .yp-case-study {
    grid-template-columns: 1008px;
  }
}

.yp-case-study-head {
  margin: 0 0 24px;
  font-weight: bold;
  font-size: 28px;
  color: #3d3d3d;
}

.yp-case-study-text {
  margin: 0;
  font-size: 16px;
  color: #3d3d3d;
  line-height: 28px;
}

.yp-case-study-text--bold {
  font-weight: bold;
}

.yp-case-study-text--margin-top-24 {
  margin-top: 24px;
}

.yp-case-study-text--margin-bottom-12 {
  margin-bottom: 12px;
}

.yp-case-study-text--margin-bottom-24 {
  margin-bottom: 24px;
}

.yp-case-study-text--margin-bottom-34 {
  margin-bottom: 34px;
}

.yp-case-study-text--margin-bottom-48 {
  margin-bottom: 48px;
}

.yp-case-study-text--margin-bottom-72 {
  margin-bottom: 72px;
}

.yp-case-study-two-columns-block {
  display: grid;
  grid-template-columns: 428px 618px;
  grid-gap: 40px;
}

@media only screen and (max-width: 1140px) {
  .yp-case-study-two-columns-block {
    grid-template-columns: 396px 572px;
  }
}

.yp-case-study-link {
  color: #fbfbfb;
}

.yp-case-study-slider {
  height: 665px;
}

.yp-case-study--fintech-image-1 {
  height: 2431px;
  background: transparent url("assets/images/fintech-wireframing.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--fintech-image-2 {
  height: 896px;
  background: transparent url("assets/images/fintech-style-guide-1.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--fintech-image-3 {
  height: 2341px;
  background: transparent url("assets/images/fintech-style-guide-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--fintech-image-4 {
  height: 5128px;
  background: transparent url("assets/images/fintech-style-guide-3.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--fintech-image-5 {
  height: 1078px;
  background: transparent url("assets/images/fintech-linked-prototype-1.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--fintech-image-6 {
  height: 642px;
  background: transparent url("assets/images/fintech-linked-prototype-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-1 {
  height: 813px;
  background: transparent url("assets/images/presale-estimation-1.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-2 {
  height: 595px;
  background: transparent url("assets/images/presale-estimation-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-3 {
  height: 401px;
  background: transparent url("assets/images/presale-raci.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-4 {
  height: 433px;
  background: transparent url("assets/images/presale-scheme-1.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-5 {
  height: 541px;
  background: transparent url("assets/images/presale-scheme-2.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-6 {
  height: 541px;
  background: transparent url("assets/images/presale-sketches.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-7 {
  height: 1512px;
  background: transparent url("assets/images/presale-scope-modules.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--presale-image-8 {
  height: 402px;
  background: transparent url("assets/images/presale-persona.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--health-image-1 {
  height: 426px;
  background: transparent url("assets/images/health-phones.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--health-image-2 {
  height: 808px;
  background: transparent url("assets/images/health-tablet.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--health-image-3 {
  height: 435px;
  background: transparent url("assets/images/health-flow.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--health-image-4 {
  height: 743px;
  background: transparent url("assets/images/health-user-stories.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--health-image-5 {
  height: 1442px;
  background: transparent url("assets/images/health-wireframes.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--health-image-6 {
  height: 1800px;
  background: transparent url("assets/images/health-prototype.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-1 {
  height: 514px;
  background: transparent url("assets/images/steptribe-analysis.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-2 {
  height: 611px;
  background: transparent url("assets/images/steptribe-hypothesis.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-3 {
  height: 251px;
  background: transparent url("assets/images/steptribe-scheme-1.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-4 {
  height: 430px;
  background: transparent url("assets/images/steptribe-scheme-2.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-5 {
  height: 567px;
  background: transparent url("assets/images/steptribe-scheme-3.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-6 {
  height: 1334px;
  background: transparent url("assets/images/steptribe-wireframes-1.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-7 {
  height: 1140px;
  background: transparent url("assets/images/steptribe-wireframes-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-8 {
  height: 702px;
  background: transparent url("assets/images/steptribe-prototype-1.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-9 {
  height: 676px;
  background: transparent url("assets/images/steptribe-prototype-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-10 {
  height: 432px;
  background: transparent url("assets/images/steptribe-questionnaire.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-11 {
  height: 235px;
  background: transparent url("assets/images/steptribe-variants.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-12 {
  height: 614px;
  background: transparent url("assets/images/steptribe-final-1.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-13 {
  height: 910px;
  background: transparent url("assets/images/steptribe-final-2.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-14 {
  height: 2713px;
  background: transparent url("assets/images/steptribe-styleguide.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-15 {
  height: 879px;
  background: transparent url("assets/images/steptribe-logo.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-16 {
  height: 517px;
  background: transparent url("assets/images/steptribe-another.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--steptribe-image-17 {
  height: 614px;
  background: transparent url("assets/images/steptribe-final-ui.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-total-scheme {
  width: 603px;
  height: 412px;
  background: transparent url("assets/images/product-total-scheme.png")
    no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-scheme-redesigned {
  height: 461px;
  background: transparent url("assets/images/product-scheme-redesigned.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-multipack {
  height: 461px;
  background: transparent url("assets/images/product-multipack.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-container {
  height: 403px;
  background: transparent url("assets/images/product-container.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-modules {
  height: 242px;
  background: transparent url("assets/images/product-modules.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-builder {
  width: 931px;
  height: 434px;
  background: transparent url("assets/images/product-builder.png") no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-total-scheme-2 {
  height: 608px;
  background: transparent url("assets/images/product-total-scheme-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-devices-scheme {
  width: 740px;
  height: 641px;
  background: transparent url("assets/images/product-devices-scheme.png")
    no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-adjusment {
  height: 725px;
  background: transparent url("assets/images/product-adjusment.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-wireframes {
  height: 1945px;
  background: transparent url("assets/images/product-wireframes.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-wr-tap {
  height: 725px;
  background: transparent url("assets/images/product-wr-tap.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-banner {
  height: 611px;
  background: transparent url("assets/images/product-banner.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ui-tap {
  height: 816px;
  background: transparent url("assets/images/product-ui-tap.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ui-screens {
  width: 100%;
  height: 1280px;
  background: transparent url("assets/images/product-ui-screens.png") no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-design-system {
  width: 100%;
  height: 2742px;
  background: transparent url("assets/images/product-design-system.png")
    no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-prototype {
  height: 1629px;
  background: transparent url("assets/images/product-prototype.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-banner-macbook {
  height: 725px;
  background: transparent url("assets/images/product-banner-macbook.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ios-flow {
  width: 660px;
  height: 551px;
  background: transparent url("assets/images/product-ios-flow.png") no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-ios-wireframes {
  height: 929px;
  background: transparent url("assets/images/product-ios-wireframes.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ios-styleguide {
  height: 385px;
  background: transparent url("assets/images/product-ios-styleguide.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ios-design {
  height: 772px;
  background: transparent url("assets/images/product-ios-design.png") no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ios-design-2 {
  height: 772px;
  background: transparent url("assets/images/product-ios-design-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-ios-prototype {
  height: 585px;
  background: transparent url("assets/images/product-ios-prototype.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-website-flow {
  width: 696px;
  height: 456px;
  background: transparent url("assets/images/product-website-flow.png")
    no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-website-wireframes {
  height: 849px;
  background: transparent url("assets/images/product-website-wireframes.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-website-wireframes-2 {
  width: 100%;
  height: 780px;
  background: transparent url("assets/images/product-website-wireframes-2.png")
    no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-website-ui-tap {
  height: 816px;
  background: transparent url("assets/images/product-website-ui-tap.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-website-ui-tap-2 {
  width: 100%;
  height: 1701px;
  background: transparent url("assets/images/product-website-ui-tap-2.png")
    no-repeat;
  background-size: 100% 100%;
  justify-self: center;
}

.yp-case-study--product-website-styleguide {
  height: 1909px;
  background: transparent url("assets/images/product-website-styleguide.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-website-prototype {
  height: 547px;
  background: transparent url("assets/images/product-website-prototype.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-wireframes {
  height: 1865px;
  background: transparent url("assets/images/product-web-apps-wireframes.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-catalog {
  height: 2835px;
  background: transparent url("assets/images/product-web-apps-catalog.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-1 {
  height: 582px;
  background: transparent url("assets/images/product-web-apps-ui-1.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-2 {
  height: 597px;
  background: transparent url("assets/images/product-web-apps-ui-2.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-3 {
  height: 509px;
  background: transparent url("assets/images/product-web-apps-ui-3.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-4 {
  height: 545px;
  background: transparent url("assets/images/product-web-apps-ui-4.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-5 {
  height: 529px;
  background: transparent url("assets/images/product-web-apps-ui-5.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-6 {
  height: 612px;
  background: transparent url("assets/images/product-web-apps-ui-6.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-7 {
  height: 455px;
  background: transparent url("assets/images/product-web-apps-ui-7.png")
    no-repeat;
  background-size: 100% 100%;
}

.yp-case-study--product-web-apps-ui-8 {
  height: 916px;
  background: transparent url("assets/images/product-web-apps-ui-8.png")
    no-repeat;
  background-size: 100% 100%;
}
