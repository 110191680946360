/* todo: fix left vertical white line */
.yp-case-study-banner {
  display: flex;
  height: 482px;
  margin-left: -5px;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.yp-case-study-banner--finTech {
  background-image: url("assets/images/banner-fintech-1920.png");
}

.yp-case-study-banner--preSale {
  background-image: url("assets/images/banner-presale-1920.png");
}

.yp-case-study-banner--health {
  background-image: url("assets/images/banner-health-1920.png");
}

.yp-case-study-banner--stepTribe {
  background-image: url("assets/images/banner-steptribe-1920.png");
}

.yp-case-study-banner--productDesign {
  background-image: url("assets/images/banner-product-design-1920.png");
}

@media only screen and (max-width: 1660px) {
  .yp-case-study-banner--finTech {
    background-image: url("assets/images/banner-fintech.png");
  }

  .yp-case-study-banner--preSale {
    background-image: url("assets/images/banner-presale.png");
  }

  .yp-case-study-banner--health {
    background-image: url("assets/images/banner-health.png");
  }

  .yp-case-study-banner--stepTribe {
    background-image: url("assets/images/banner-steptribe.png");
  }

  .yp-case-study-banner--productDesign {
    background-image: url("assets/images/banner-product-design.png");
  }
}

.yp-case-study-banner-description {
  display: flex;
  width: 494px;
  margin-left: 56%;
  flex-direction: column;
}

.yp-case-study-banner-head {
  margin: 0 0 32px;
  font-size: 28px;
  font-weight: bold;
  color: #fbfbfb;
}

.yp-case-study-banner-text {
  display: inline-block;
  margin-bottom: 26px;
  font-size: 16px;
  color: #fbfbfb;
  line-height: 24px;
}

.yp-case-study-banner-text:last-child {
  margin-bottom: 0;
}

.yp-case-study-banner-text--bold {
  font-weight: bold;
}
