.yp-case-study-navigation {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}

.yp-case-study-navigation-item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.yp-case-study-navigation-item--up {
  margin: 0 80px;
  cursor: pointer;
}

.yp-case-study-navigation-item--align-right {
  align-items: flex-end;
}

.yp-case-study-navigation-item--align-center {
  align-items: center;
}

.yp-case-study-navigation-item--align-left {
  align-items: flex-start;
}

.yp-case-study-navigation-item-arrow {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-bottom: 10px;
}

.yp-case-study-navigation-item-arrow--left {
  background: transparent url("assets/images/arrow-left.svg") 50% no-repeat;
  background-size: 14px;
}

.yp-case-study-navigation-item-arrow--up {
  background: transparent url("assets/images/arrow-up.svg") 50% no-repeat;
  background-size: 14px;
}

.yp-case-study-navigation-item-arrow--right {
  background: transparent url("assets/images/arrow-right.svg") 50% no-repeat;
  background-size: 14px;
}

.yp-case-study-navigation-item-title {
  font-size: 14px;
  font-weight: bold;
  color: #3d3d3d;
  text-transform: uppercase;
}
