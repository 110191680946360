.yp-tabs {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.yp-tabs-wrapper {
  display: flex;
  width: 1087px;
  justify-content: space-between;
}

@media only screen and (max-width: 1140px) {
  .yp-tabs-wrapper {
    width: 1008px;
  }
}

.yp-tab {
  padding: 28px 44px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #3d3d3d;
  text-decoration: none;
  transition: color 200ms ease-in;
}

.yp-tab:hover {
  color: #ffbc07;
}

.yp-tab--active {
  color: #ffbc07;
  border-bottom: 2px solid #ffbc07;
}
