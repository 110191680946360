.yp-art {
  display: grid;
  margin-top: 41px;
  margin-bottom: 80px;
  grid-template-columns: 536px 536px;
  grid-gap: 16px;
  justify-content: center;
}

@media only screen and (max-width: 1140px) {
  .yp-art {
    grid-template-columns: 496px 496px;
  }
}

.yp-art-image {
  height: 360px;
}

.yp-art-image-1 {
  background: transparent url("assets/images/art-1.png") no-repeat;
  background-size: 100% 100%;
}

.yp-art-image-2 {
  background: transparent url("assets/images/art-2.png") no-repeat;
  background-size: 100% 100%;
}

.yp-art-image-3 {
  background: transparent url("assets/images/art-3.png") no-repeat;
  background-size: 100% 100%;
}

.yp-art-image-4 {
  background: transparent url("assets/images/art-4.png") no-repeat;
  background-size: 100% 100%;
}

.yp-art-image-5 {
  background: transparent url("assets/images/art-5.png") no-repeat;
  background-size: 100% 100%;
}

.yp-art-image-6 {
  background: transparent url("assets/images/art-6.png") no-repeat;
  background-size: 100% 100%;
}
