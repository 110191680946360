.yp-about {
  display: grid;
  margin-top: 41px;
  grid-template-columns: 818px;
  justify-content: center;
}

.yp-about-text {
  margin: 0;
  font-size: 16px;
  color: #fbfbfb;
  line-height: 26px;
}

.yp-about-text--margin-top-16 {
  margin-top: 16px;
}

.yp-about-text--margin-top-38 {
  margin-top: 38px;
}

.yp-about-divider {
  height: 56px;
  margin: 26px 0 20px;
  background: transparent url("assets/images/cubes-divider.png") 50% no-repeat;
  background-size: 64px 64px;
}
