.yp-layout {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}

.yp-layout--dark {
  background-color: #2f3236;
}

.yp-layout--light {
  background-color: #fbfbfb;
}

.yp-layout-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-self: center;
}
