.yp-activities {
  display: grid;
  margin-top: 41px;
  margin-bottom: 48px;
  grid-template-columns: 1274px;
  justify-content: center;
}

@media only screen and (max-width: 1140px) {
  .yp-activities {
    grid-template-columns: 1024px;
  }
}

.yp-activity {
  display: grid;
  width: 1180px;
  height: 360px;
  align-items: center;
}

@media only screen and (max-width: 1140px) {
  .yp-activity {
    width: 1024px;
  }
}

.yp-activity--ltr {
  grid-template-columns: 96px 536px 644px;
  grid-template-areas: ". photo description";
}

@media only screen and (max-width: 1140px) {
  .yp-activity--ltr {
    grid-template-columns: 76px 430px 516px;
  }
}

.yp-activity--rtl {
  grid-template-columns: 644px 536px 96px;
  grid-template-areas: "description photo .";
}

@media only screen and (max-width: 1140px) {
  .yp-activity--rtl {
    grid-template-columns: 516px 430px 76px;
  }
}

.yp-activity-photo {
  height: 100%;
  grid-area: photo;
}

.yp-activity-description {
  height: 282px;
  padding: 62px 32px 0 68px;
  box-sizing: border-box;
  box-shadow: 3px 3px 12px #00000080;
  grid-area: description;
}

.yp-activity-text {
  margin: 0;
  padding-left: 18px;
  font-size: 16px;
  color: #fbfbfb;
  line-height: 30px;
}

.yp-activity--bold-text {
  font-weight: bold;
}

.yp-activity-text--bullet {
  position: relative;
}

.yp-activity-text--bullet::before {
  position: absolute;
  left: 0;
  top: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffbc07;
  content: "";
}
