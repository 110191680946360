.yp-carousel {
  position: relative;
  height: 100%;
}

.yp-carousel-image {
  height: 100%;
  transition: background 0.2s ease-in;
}

.yp-carousel-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48px;
  height: 48px;
  margin: auto 0;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.yp-carousel-button--left {
  left: 16px;
  background: transparent url("assets/images/slider-btn-left.svg") 50% no-repeat;
  background-size: 48px;
}

.yp-carousel-button--right {
  right: 16px;
  background: transparent url("assets/images/slider-btn-right.svg") 50%
    no-repeat;
  background-size: 48px;
}
