.yp-banner {
  display: flex;
  height: 402px;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.yp-banner-head {
  margin: 0;
  width: 786px;
  font-size: 28px;
  font-weight: bold;
  color: #fcfcfc;
  text-align: center;
  line-height: 1.7;
}

.yp-banner--portfolio {
  background-image: url("assets/images/banner-portfolio-1920.png");
}

.yp-banner--art {
  background-image: url("assets/images/banner-art-1920.png");
}

.yp-banner--activities {
  background-image: url("assets/images/banner-activities-1920.png");
}

.yp-banner--about {
  background-image: url("assets/images/banner-about-1920.png");
}

@media only screen and (max-width: 1660px) {
  .yp-banner--portfolio {
    background-image: url("assets/images/banner-portfolio.png");
  }

  .yp-banner--art {
    background-image: url("assets/images/banner-art.png");
  }

  .yp-banner--activities {
    background-image: url("assets/images/banner-activities.png");
  }

  .yp-banner--about {
    background-image: url("assets/images/banner-about.png");
  }
}
