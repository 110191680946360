.yp-portfolio-tiles {
  position: relative;
  display: grid;
  margin-top: 16px;
  margin-bottom: 132px;
  grid-template-columns: 536px 536px;
  grid-gap: 16px;
  justify-content: center;
  align-self: center;
}

@media only screen and (max-width: 1140px) {
  .yp-portfolio-tiles {
    grid-template-columns: 496px 496px;
  }
}

.yp-portfolio-tile {
  height: 360px;
  z-index: 1;
}

.yp-portfolio-tile a {
  display: block;
  height: 100%;
}

.yp-portfolio-tile--1 {
  background: transparent url("assets/images/portfolio-tile-1.jpg") no-repeat;
  background-size: 100% 100%;
}

.yp-portfolio-tile--2 {
  background: transparent url("assets/images/portfolio-tile-2.png") no-repeat;
  background-size: 100% 100%;
}

.yp-portfolio-tile--3 {
  background: transparent url("assets/images/portfolio-tile-3.jpg") no-repeat;
  background-size: 100% 100%;
}

.yp-portfolio-tile--4 {
  background: transparent url("assets/images/portfolio-tile-4.png") no-repeat;
  background-size: 100% 100%;
}

.yp-portfolio-tile--5 {
  background: transparent url("assets/images/portfolio-tile-5.png") no-repeat;
  background-size: 100% 100%;
}

.yp-portfolio-full-size-tile {
  width: 1088px;
  height: 420px;
  grid-column: 1 / 3;
}

@media only screen and (max-width: 1140px) {
  .yp-portfolio-full-size-tile {
    width: 1008px;
  }
}
