.yp-header {
  display: grid;
  padding: 28px 140px 28px 92px;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  background-color: #2f3236;
}

.yp-logo {
  width: 170px;
  height: 75px;
  background: transparent url("assets/images/logo-dark.png") 50% -4px no-repeat;
  background-size: cover;
}

.yp-logo a {
  display: block;
  height: 100%;
}

.yp-navigation {
  display: flex;
  align-self: center;
  justify-self: end;
}

.yp-navigation-link {
  position: relative;
  display: inline-block;
  min-width: 42px;
  margin-right: 55px;
  color: #fbfbfb;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: color 200ms ease-in;
}

.yp-navigation-link:hover {
  color: #ffbc07;
}

.yp-navigation-link:last-child {
  margin-right: 0;
}

.yp-navigation-link--active {
  color: #ffbc07;
}

.yp-navigation-link--active::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  display: inline-block;
  margin: 0 auto;
  width: 42px;
  height: 2px;
  background-color: #ffbc07;
  content: "";
}
