@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

html,
body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.yp-full-width-content {
  display: grid;
  width: 1366px;
  align-self: center;
}

.yp-design-image-cubes::after {
  position: absolute;
  width: 266px;
  height: 245px;
  background: transparent url("assets/images/cubes.svg") no-repeat;
  background-size: contain;
  content: "";
}

.yp-design-image-cubes--portfolio-page.yp-design-image-cubes::after {
  right: 0;
  bottom: -205px;
}

.yp-design-image-cubes--cv-page.yp-design-image-cubes::after {
  left: 0;
  bottom: -100px;
}

.yp-not-available {
  display: none;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2f3236 url("assets/images/site-is-not-available-banner.jpg") 50%
    no-repeat;
  background-size: cover;
}

.yp-not-available__logo {
  width: 172px;
  height: 120px;
  margin-bottom: 70px;
  background: url("assets/images/logo-mobile.png") 50% no-repeat;
}

.yp-not-available__message {
  max-width: 70%;
  font-size: 28px;
  font-weight: bold;
  color: #fbfbfb;
  text-align: center;
  line-height: 2;
}

@media only screen and (max-width: 1022px) {
  #root {
    display: none;
  }

  .yp-not-available {
    display: flex;
  }
}
