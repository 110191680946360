.yp-cv {
  display: grid;
  margin-bottom: 80px;
  grid-template-columns: 536px 536px;
  grid-gap: 50px;
  justify-content: center;
}

@media only screen and (max-width: 1140px) {
  .yp-cv {
    grid-template-columns: 472px 472px;
  }
}

.yp-cv-left-column {
  position: relative;
}

.yp-cv-photo {
  width: 100%;
  height: 360px;
  background: transparent url("assets/images/yana-photo.png") no-repeat;
  background-size: 110% 110%;
  background-position: 24% 34%;
}

@media only screen and (max-width: 1140px) {
  .yp-cv-photo {
    height: 330px;
  }
}

.yp-cv-head {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 28px;
  font-weight: bold;
  color: #fbfbfb;
}

.yp-cv-text {
  margin: 0;
  font-size: 16px;
  color: #fbfbfb;
  line-height: 30px;
}

.yp-cv-text--bullet {
  position: relative;
  padding-left: 12px;
}

.yp-cv-text--bullet::before {
  position: absolute;
  left: 0;
  top: 12px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffbc07;
  content: "";
}

.yp-cv-text--bullet-check::before {
  position: absolute;
  left: 0;
  top: 12px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: transparent url("assets/images/check.png") no-repeat;
  background-size: 8px 8px;
  content: "";
}

.yp-cv-divider {
  margin: 24px 0;
}

.yp-cv-divider::before {
  display: inline-block;
  width: 60px;
  height: 2px;
  background-color: #ffbc07;
  content: "";
}

.yp-cv--top-margin-40 {
  margin-top: 40px;
}

.yp-cv--top-margin-24 {
  margin-top: 24px;
}

.yp-cv--bold-text {
  font-weight: bold;
}

.yp-cv--line-height-32 {
  line-height: 36px;
}

.yp-cv-link {
  transition: color 200ms ease-in;
  text-decoration: none;
}

.yp-cv-link:hover {
  color: #ffbc07;
  text-decoration: underline;
}
